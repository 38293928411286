import { Link } from "react-router-dom";

const Sidebar = ()=>{
    return (
        <>
            <aside className="bg-gray-700 text-white w-1/6 min-h-screen p-4">
                <nav>
                    <h1 className="text-base">Admin</h1>
                    <ul className="space-y-2">
                        <li className="">
                            <Link to="">
                                <div className="p-2 hover:bg-gray-800">
                                    <i className="fa fa-user px-2"></i>Customer
                                </div>
                            </Link>
                        </li>

                        <li className="">
                            <Link to="product">
                                <div className="p-2 hover:bg-gray-800">
                                    <i className="fa fa-car px-2"></i>Product
                                </div>
                            </Link>
                        </li>

                        <li className="">
                            <Link to="order">
                                <div className="p-2 hover:bg-gray-800">
                                    <i className="fa fa-copy px-2"></i>Order
                                </div>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </aside>
        </>
    )
}
export default Sidebar;