import { Link } from "react-router-dom";

const Hero = ()=>{
    return(
        <>
            <section className="overflow-hidden mb-5 md:mb-5" id="home">
    <div className="absolute inset-x-0 top-32 ">
        <div aria-hidden="true" className="grid grid-cols-2 -space-x-52 opacity-50 dark:opacity-60 2xl:mx-auto 2xl:max-w-6xl">
            <div className="h-60 bg-gradient-to-br from-primary to-purple-400 blur-3xl dark:from-blue-700"></div>
            <div className="h-72 rounded-full bg-gradient-to-r from-cyan-400 to-sky-300 blur-3xl dark:from-transparent dark:to-indigo-600"></div>
        </div>
    </div>
    <div className="mx-auto max-w-6xl px-6 md:px-12 lg:px-6 xl:px-0">
        <div className="relative pt-28">
            <div className="w-full block md:flex md:justify-between md:items-center">
                <div className="text-center sm:px-12 md:w-2/3 md:px-0 md:text-left lg:w-1/2">
                    <h1 className="text-5xl font-medium md:font-black dark:text-white md:text-6xl xl:text-7xl">Business management made easy</h1>
                    <div className="">
                        <p className="mt-8 text-lg text-gray-700 dark:text-gray-100">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, nam saepe consequuntur libero quos dolores explicabo at, alias nesciunt natus consectetur iste? Modi, excepturi? Quo natus corporis ullam consectetur repellat!</p>
                        <div className="mt-7">
                            <Link to="/products" className="bg-cyan-400 p-3 text-lg border rounded-lg border-cyan-400 hover:border-sky-300 hover:bg-sky-300"><span className="text-white astro-Z4ZYBUMP">Show All product</span></Link>
                        </div>
                    </div>
                </div>
                <div className="relative mt-8 md:mt-0 md:w-2/5 lg:w-3/5">
                    <div className="-ml-6 md:-mr-72 lg:mr-0">
                        <img className="hidden h-full object-cover object-left dark:hidden" src="/hero.webp" alt="app screenshot" width="1628" height="1233" />
                        <img className="h-[44%] sm:h-full object-cover object-left dark:block" src="/hero-dark.webp" alt="app screenshot" width="1628" height="1233" />
                    </div>
                </div>
            </div>

        </div>
    </div>
            </section>
        </>
    )
}
export default Hero;