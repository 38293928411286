import Cookies from "js-cookie";
import { useLayoutEffect,useState,useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../services/context/cartContext";


const Header = ()=>{
    const {cart} = useContext(CartContext);
    const [islogin,setisLogin] = useState(false);
    const [isAdmin,setisAdmin] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleToggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    
    const navigate = useNavigate();
    useLayoutEffect(()=>{
        if (Cookies.get('AdminToken') !== undefined){
            setisAdmin(true);
            setisLogin(true);
        }
        if(Cookies.get('UserToken') !== undefined){
            setisLogin(true);
        }
    },[])
    const logout = ()=>{
        if(isMenuOpen){
            handleToggleMenu();
        }
        if(isAdmin){
        Cookies.remove('AdminToken');
        setisLogin(false);
        }
        else if(islogin){
            Cookies.remove('UserToken');
            setisLogin(false);
        }
        
        navigate("/login"); 
        console.log("logout")
    }
    return (<>


        <nav className={`z-10 w-full border-b border-black/5 fixed top-0 lg:border-transparent jay_plastic_nav  bg-white`}>
            <div className="mx-auto max-w-6xl px-6 md:px-12 lg:px-6 xl:px-0">
                <div className="relative flex flex-wrap items-center justify-between gap-3 py-3 md:gap-0 md:py-4 jay_plastic_nav">
                    <div className="relative z-20 flex w-full justify-between md:px-0 lg:w-max jay_plastic_nav">
                        <div className="flex gap-2 items-center">
                            <img src="/logo512.png" alt="logo" className="h-10 w-10" />
                            <div>
                        <Link to="/" aria-label="logo" className="flex items-center space-x-2 jay_plastic_nav">
                            <span className="text-2xl font-bold text-[#1A7499] jay_plastic_nav">Jay Plastic</span>
                        </Link>
                        <p className="text-xs text-gray-500">GST : 1234456778899</p>
                        </div>
                        </div>

                        <div className="relative flex items-center justify-center">

                            {/* <!-- cart icone for mobile device --> */}
                            <div className=" lg:hidden lg:mt-0 mr-5 jay_plastic_nav">
                                <Link to="/cart" className="hover:text-blue-500 text-gray-600 block transition  md:px-4 jay_plastic_nav">
                                    <span className="jay_plastic_nav">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
                                            <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
                                        </svg>
                                    </span>
                                    <span className="absolute -mt-6 ml-3 rounded-full bg-red-600 px-[0.35em] py-[0.15em] text-[0.6rem] font-bold leading-none text-white " id="cart_item_count_span_small_screen">
                                        {islogin ? cart : "0"}                                                                                                                                                              
                                    </span>
                                </Link>
                            </div>

                            {/* <!-- this is open menu for mobile device --> */}
                            <div className="relative flex max-h-10 items-center lg:hidden jay_plastic_nav" onClick={handleToggleMenu}>
                                <button aria-label="humburger" id="hamburger" className={`relative -mr-6 p-6 jay_plastic_nav ${isMenuOpen ? "toggled ":" "}  `}>
                                    <div aria-hidden="true" id="line" className="m-auto h-0.5 w-5 rounded bg-sky-900 transition duration-300  jay_plastic_nav"></div>
                                    <div aria-hidden="true" id="line2" className="m-auto mt-2 h-0.5 w-5 rounded bg-sky-900 transition duration-300 jay_plastic_nav"></div>
                                </button>
                            </div>
                        </div>


                    </div>
                    <div id="navLayer" aria-hidden="true" className={`fixed inset-0 z-10 h-screen w-screenbg-white/70 backdrop-blur-2xl transition duration-500  lg:hidden jay_plastic_nav  ${isMenuOpen ? "origin-top scale-y-100 ": "origin-bottom scale-y-0 "} `}></div>
                    <div id="navlinks" className={`invisible absolute top-full left-0 z-20 w-full origin-top-right translate-y-1 scale-90 flex-col flex-wrap justify-end gap-3 rounded-3xl border border-gray-100 bg-white p-8 opacity-0 shadow-2xl shadow-gray-600/10 transition-all duration-300 dark:border-gray-700 dark:bg-gray-800 dark:shadow-none lg:visible lg:relative lg:flex lg:w-7/12 lg:translate-y-0 lg:scale-100 lg:flex-row lg:items-center lg:gap-0 lg:border-none lg:bg-transparent lg:p-0 lg:opacity-100 lg:shadow-none jay_plastic_nav ${isMenuOpen ? "!visible !scale-100 !opacity-100 !lg:translate-y-0 ":" "}  `}>
                        <div className="w-full text-gray-600  lg:w-auto lg:pr-4 lg:pt-0 jay_plastic_nav">
                            <ul className="flex flex-col gap-6 tracking-wide lg:flex-row lg:gap-0 lg:text-sm jay_plastic_nav">
                                <li className="jay_plastic_nav" onClick={()=>{if(isMenuOpen){
                                    handleToggleMenu();
                                }}}>
                                    <Link to="/" className="hover:text-blue-500  dark:text-white block transition  md:px-4 jay_plastic_nav">
                                        <span className="jay_plastic_nav">Home</span>
                                    </Link>
                                </li>
                                <li className="jay_plastic_nav" onClick={()=>{if(isMenuOpen){
                                    handleToggleMenu();
                                }}}>
                                    <Link to="/products" className="hover:text-blue-500 dark:text-white block transition  md:px-4 jay_plastic_nav">
                                        <span className="jay_plastic_nav">Products</span>
                                    </Link>
                                </li>
                                <li className="jay_plastic_nav" onClick={()=>{if(isMenuOpen){
                                    handleToggleMenu();
                                }}}>
                                    <Link to="/contanctUs" className="hover:text-blue-500 dark:text-white block transition  md:px-4 jay_plastic_nav">
                                        <span className="jay_plastic_nav">Contact Us</span>
                                    </Link>
                                </li>
                        {
                                    isAdmin ? (<li className="jay_plastic_nav" onClick={()=>{if(isMenuOpen){
                                        handleToggleMenu();
                                    }}}>
                                        <Link to="/admin" className="hover:text-blue-500 block transition dark:text-white  md:px-4 jay_plastic_nav">
                                            <span className="jay_plastic_nav">Admin</span>
                                        </Link>
                                    </li>):(<></>)  
                        }
                        
                        <li className="jay_plastic_nav" onClick={()=>{if(isMenuOpen){
                                    handleToggleMenu();
                                }}}>
                            <Link to="/about" className="hover:text-blue-500 block transition dark:text-white  md:px-4 jay_plastic_nav">
                                <span className="jay_plastic_nav">About Us</span>
                            </Link>
                        </li>
                        {
                                    islogin && !isAdmin ? (
                                        <li className="jay_plastic_nav" onClick={()=>{if(isMenuOpen){
                                            handleToggleMenu();
                                        }}}>
                                            <Link to="/userorder" className="hover:text-blue-500 dark:text-white block transition  md:px-4 jay_plastic_nav">
                                                <span className="jay_plastic_nav">Order</span>
                                            </Link>
                                        </li> ):null
                        }



                            </ul>
                        </div>
                        <div className="hidden lg:block mt-12 lg:mt-0 mr-5 jay_plastic_nav">
                            <Link to="/cart" className="hover:text-blue-500 text-gray-600 dark:text-white block transition  md:px-4 jay_plastic_nav">
                                <span className="jay_plastic_nav">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
                                        <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
                                    </svg>
                                </span>
                                <span className="absolute -mt-6 ml-3 rounded-full bg-red-600 px-[0.35em] py-[0.15em] text-[0.6rem] font-bold leading-none text-white" id="cart_item_count_span_big_screen">
                                {islogin ? cart : "0"}       
                                </span>
                            </Link>
                        </div>

                        <div className="mt-12 lg:mt-0 jay_plastic_nav">
                            <button onClick={logout} className={`relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full ${islogin ? 'before:bg-red-400' :'before:bg-blue-500'} before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max jay_plastic_nav`}>
                                <span className="relative text-sm font-semibold text-white jay_plastic_nav">{islogin ? "Logout":"Login/Sign"}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </>)
}
export default Header;