import { useState, useEffect } from "react";
import Cookies from 'js-cookie';
function useGetAllUserOrder() {
    const [arrayValues, setArrayValues] = useState();
    const [error, setError] = useState("");
    useEffect(() => {
        fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/user/route_order.php`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('UserToken') || Cookies.get('AdminToken')})`
            },
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setArrayValues(res.data);
                }
                else {
                    setError("somethig went wrong")
                }


            })
            .catch(e => console.log("error : " + e));

    }, [])
    return [arrayValues, setArrayValues, error];
}
export default useGetAllUserOrder;