import React, { useState } from 'react';
import { MdAdd, MdClose } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import useGetAllCategory from '../hook/useGetAllCategory';
import useGetAllGstRate from '../hook/useGetAllGstRate';
import createCategory from '../apiCalls/createCategory';
import createGst from '../apiCalls/createGst';
import Cookies from 'js-cookie';

const CreateProduct = ()=>{
    const [images, setImages] = useState([]);
    const [imgErrorMsg,setImgErrorMsg] = useState("");
    const [error,setError] = useState("");
    const [data,setData] = useState({});
    const navigate = useNavigate();
    const { categories,fetchCategories } = useGetAllCategory([]);
    const { gstRates,fetchGstRates } = useGetAllGstRate([]);
    // const [index,setIndex] = useState(0);

    const handleCreateCategory = async () => {
        const result = await createCategory();
        if(result){
            fetchCategories();
        }
    };

    const handleCreateGst = async ()=>{
        const result = await createGst();
        if(result){
            fetchGstRates();
        }
    }

    const handleImageChange = (event,index) => {
        const newImages = [...images];
        const file = event.target.files[0];
        const maxSize = 500 * 1024; // 500 KB
        if (file.size > maxSize) {
            setImgErrorMsg('File size should be less than 500 KB.');
            return;
        }

        const allowedExtensions = ['jpg', 'jpeg', 'png', 'webp'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
            setImgErrorMsg('Allowed file extensions are jpg, jpeg, png, and webp.');
            return;
        }
        newImages[index] = file;
        setImages(newImages);
        setImgErrorMsg('');
        // setIndex((prevIndex)=>prevIndex+1)
     
    };
    // console.log(gstRate);

    const handleAddImage = () => {
       
        if(images.length < 5){
            setError("")
            setImages([...images, {name:"No File chosen"}]);
            
        }
        else{
            setImgErrorMsg("you can add only 5 imges");
        }
        
    };
    const handleData=(e)=>{
      
        const { name, value ,type, checked } = e.target;
        if (type === 'checkbox'){
            let temp = 0;
            if(checked){
                temp = 1;
            }
            setData((prevData) => ({
                ...prevData,
                [name]: temp,
            }));
        }
        else{
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
            
            
    }
    }
    const sendData =(e) => {
        e.preventDefault();

        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });

        if (!data.checkbox) {
           formData.append("checkbox",0);
        }
    

        images.forEach((image, index) => {
            formData.append(`image${index + 1}`, image);
        });

        

        fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/route_product.php`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${Cookies.get('AdminToken')})`
            },
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
            
                // Handle success or failure
                if (data.success){
                    navigate("/admin/product")
                    // console.log(data.message);
                }
                else{
                    setError(data.message)
                }
            })
            .catch(error => console.error('Error registering product:', error));
    
     
    };

    const deleteImages = (deletedIndex) => {
        setImages((prevImages) => prevImages.filter((_, index) => index !== deletedIndex));
    };


    return (
        <div>
             <div className="w-full ml-3 flex justify-center">
        

        
            <div className="min-h-screen flex flex-col items-center">
                    <div className="flex flex-col  bg-gray-300 shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full ">
                    <div className="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
                        Add Product</div>

                    <div className="mt-10">
                        <p id="product_error" className="text-red-500"></p>
                        <form id="productForm" encType="multipart/form-data" onSubmit={sendData}>
                            <div className="flex flex-col mb-6">
                                <label htmlFor="name" className="mb-1 text-xs sm:text-sm tracking-wide text-left text-gray-600">Product Name:</label>
                                

                                    <input id="product_name" required value={data.product_name} type="text" name="product_name" className="text-sm sm:text-base placeholder-gray-500 pl-2 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Product Name" onChange={handleData} />
                                
                            </div>

                            <div className="flex flex-col mb-6">
                                    <label htmlFor="img" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 text-left">Product Image:</label>
                                    <div className='text-red-500 text-sm text-left'>{imgErrorMsg}</div>
                                    <button type='button' className='w-fit p-2.5 ml-2 rounded-lg border border-gray-400 focus:outline-none focus:border-blue-400' onClick={handleAddImage}>
                                            <MdAdd className='text-2xl' />
                                    </button>
                                       
                                       
                                   
                                    { images.map((image, index) => (
                                        <div key={index} className={`image-input-${index} inline-flex justify-between items-center`}>
                                            <label htmlFor={`img${index + 1}`} className="custom-file-input-label text-sm  sm:text-base bg-white pl-2 pr-4 rounded-lg border my-1 mx-1 border-gray-400 w-fit py-2 focus:outline-none focus:border-blue-400">
                                                Choose a file
                                            </label>
                                            <input
                                                id={`img${index + 1}`}
                                                type="file"
                                                

                                                className="hidden text-sm  sm:text-base bg-white pl-2 pr-4 rounded-lg border my-1 border-gray-400 w-fit py-2 focus:outline-none focus:border-blue-400"
                                                accept="image/*"
                                                onChange={(event) => handleImageChange(event, index)}
                                            
                                            />
                                            <span className="file-name px-2 truncate max-w-[150px] text-left">{image ? image.name : 'No file chosen'}</span>
                                            <button type='button' className='w-fit p-2.5 ml-2 rounded-lg border border-gray-400 focus:outline-none focus:border-blue-400' onClick={() => deleteImages(index)}>
                                                <MdClose className='text-2xl' />
                                            </button>
                                            
                                        </div>
                                    ))}

                            </div>

                            <div className="flex flex-col mb-6">
                                    <label htmlFor="category" required className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 text-left">Category:</label>
                                
                                    <div className='flex justify-center'>
                                    <select id="product_catagry" required  name="category_name" className="text-sm sm:text-base placeholder-gray-500 pl-2 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" onChange={handleData}>
                                        <option className="text-gray-500" value="">select Category</option>
                                        {
                                                categories?.map((data)=>{
                                                return (
                                                    
                                                        <option className="text-gray-500" key={data.id} value={data.category_name}>{ data.category_name }</option>
                                                    
                                                )
                                            })
                                        }
                                    </select>

                                        <div className='bg-gray-700 w-[2.5rem] ml-2 flex justify-center items-center rounded-lg ' onClick={handleCreateCategory}>
                                            <MdAdd className='text-3xl text-white'/>
                                    </div>
                                    </div>
                            
                            </div>

                            <div className="flex flex-col mb-6">
                                    <label htmlFor="number" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 text-left">Description:</label>
                                <div className="relative">
                                        <textarea id="" required name="product_desc" cols="30" rows="5" className="text-sm sm:text-base placeholder-gray-500 pl-2 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="description...." onChange={handleData}></textarea>
                                </div>
                            </div>
                                <div className="flex flex-col ">
                                    <label htmlFor="price" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 text-left">Product Packaging:</label>
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="flex flex-col mb-6">
                                            <label htmlFor="price" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 text-left">inner package:</label>
                                            <div className="">
                                                <input id="inner_package" required type="number" min="1" name="inner_package" className="text-sm sm:text-base placeholder-gray-500 pr-5 pl-2 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Qty" onChange={handleData} />
                                            </div>
                                        </div>
                                        <div className="flex flex-col mb-6">
                                            <label htmlFor="price" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 text-left">master package:</label>
                                            <div className="">
                                                <input id="master_package" required type="number" min="1" name="master_package" className="text-sm sm:text-base placeholder-gray-500 pr-5 pl-2  rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Qty" onChange={handleData} />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            <div className="flex flex-col ">
                                    <label htmlFor="price" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 text-left">Product Price:</label>
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="flex flex-col mb-6">
                                            <label htmlFor="price" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 text-left">A:</label>
                                        <div className="">
                                                <input id="a_price" required type="number" min="1" name="customer_price" className="text-sm sm:text-base placeholder-gray-500 pr-5 pl-2  rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Price" onChange={handleData} />
                                        </div>
                                    </div>
                                    <div className="flex flex-col mb-6">
                                            <label htmlFor="price" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 text-left">Distrubuter:</label>
                                        <div className="">
                                                <input id="b_price" required type="number" min="1" name="distrubuter_price" className="text-sm sm:text-base placeholder-gray-500 pr-5 pl-2  rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Price" onChange={handleData} />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                                <div className="flex flex-col mb-6">
                                    <label htmlFor="category" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 text-left">Gst:</label>
                                    <div className="relative">
                                        <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                                            <i className="fa fa-list-alt mt-1"></i>
                                        </div>
                                        <div className='flex justify-center'>
                                        <select id="product_catagry" required name="gst_pre" className="text-sm sm:text-base placeholder-gray-500 pl-2 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" onChange={handleData} >
                                        
                                            <option className="text-gray-500" value="" >Select GST Rate</option>
                                            {
                                                    gstRates?.map((data) => {
                                                    return (

                                                        <option className="text-gray-500" key={data.id} value={data.gst_rate}>{data.gst_rate}%</option>

                                                    )
                                                })
                                            }
                                        </select>
                                            <div className='bg-gray-700 w-[2.5rem] ml-2 flex justify-center items-center rounded-lg ' onClick={handleCreateGst}>
                                            <MdAdd className='text-3xl text-white' />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col mb-6">
                                    <label htmlFor="number" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 text-left">Display Product:</label>
                                    <div className="inline-flex justify-start">
                                        <input type="checkbox"  name='checkbox' className='p-2' onChange={handleData} /> <span className='p-2'> is Show</span>
                                    </div>
                                </div>
                            <p className='text-red-500 text-md mb-4'>{error}</p>
                            <div className="flex w-full">
                                <button type="submit"  name="productCreateBtn" className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-400 hover:bg-blue-500 rounded py-2 w-full transition duration-150 ease-in" >
                                    <span className="mr-2 uppercase">Create</span>
                                    <span>
                                            <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </div>

        </div>
       
    );
};
export default CreateProduct;

// product name 
// product img
// category 
// product desc
// inner package
// master package
// two price e for distrubuter and another one for customer 