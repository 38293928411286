import Cookies from 'js-cookie';
const deleteProduct = (id)=>{
    fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/route_product.php?id=${id}`,{
        method: "DELETE",
        headers: {
            'Authorization': `Bearer ${Cookies.get('AdminToken')})`
        },
    }).then(res => res.json())
        .then(res => {
            if (res.success) {
                return true;
            }
            else {
                return false;
            }


        })
        .catch(e => console.log("error : " + e));
        return true;

}

export default deleteProduct;