import Swal from "sweetalert2";
import Cookies from "js-cookie";

async function callAPI(p_id) {
    try {
        const response = await fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/user/route_cart.php?p_id=${p_id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('AdminToken') || Cookies.get('UserToken')}`
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const res = await response.json();
        return res.success;
    } catch (error) {
        console.error("Error in API call:", error);
        return false;
    }
}

const deleteProductInCart = async (p_id) => {
    try {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        });

        if (result.isConfirmed) {
            const temp = await callAPI(p_id);
            if (temp) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success"
                });
                return true;
            }
        }else{
            return false;
        }
    } catch (error) {
        console.error("Error in deleteProductInCart:", error);
    }
};

export default deleteProductInCart;
