import Swal from "sweetalert2";
import Cookies from "js-cookie";

const apiCall = async (value) => {
    const data = { 'gst_rate': value }

    try {
        const response = await fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/route_gst.php`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('AdminToken')})`
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const res = await response.json();

        return res.success;
    } catch (error) {
        console.error("Error in apiCall:", error);
        return false;
    }
};

const handleApiResult = (apiResult) => {
    if (apiResult) {
        Swal.fire({
            text: "Successfully added",
            confirmButtonText: "OK",
        });
        return true;
    } else {
        Swal.fire({
            text: "Something went wrong",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "OK",
        });
        return false;
    }
};



const createGst = async () => {
    try {
        const result = await Swal.fire({
            title: "An input!",
            text: "Enter Gst Rate :",
            input: 'number',
            showCancelButton: true
        });

        if (result.value) {
            const apiResult = await apiCall(result.value);
            return handleApiResult(apiResult);
        } else {
            // Handle cancel or dismiss
            return false;
        }
    } catch (error) {
        console.error('Error in createCategory function:', error);
        return false;
    }
};

export default createGst;