import { useState, useEffect } from "react";
import Cookies from 'js-cookie';
function useGetAllOrder() {
    const [arrayValues, setArrayValues] = useState([]);
    const [error, setError] = useState("");
    useEffect(() => {
        fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/route_order.php`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('AdminToken')})`
            },
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    console.log(res)
                    setArrayValues(res.order);
                }
                else {
                    setError("somethig went wrong")
                }


            })
            .catch(e => console.log("error : " + e));

    }, [])
    return [arrayValues, setArrayValues, error];
}
export default useGetAllOrder;
