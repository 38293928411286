import Cookies from "js-cookie";
import Hero from "../components/Hero";
import ShopByCategory from "../components/ShopByCategory";
import Swiper from "../components/Swiper";
import ContactUs from "./ContactUs";

const Home = () => {

    return (
        <>
            <Hero />
            <Swiper />
            <ShopByCategory />
            <ContactUs />
        </>
    );
};
export default Home;