import Cookies from 'js-cookie';

const updateOrderStatus = (order_id,order_satus) => {
    fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/route_order.php?order_id=${order_id}&&order_status=${order_satus}`, {
        method: "PUT",
        headers: {
            'Authorization': `Bearer ${Cookies.get('AdminToken')})`
        },
    }).then(res => res.json())
        .then(res => {
            if (res.success) {
                return true;
            }
            else {
                return false;
            }


        })
        .catch(e => console.log("error : " + e));
    return true;

}

export default updateOrderStatus;