import { useEffect,useState } from "react";
import useGetInvoiceData from "../hook/useGetInvoiceData"
import { useParams } from "react-router-dom";
// import "../invoice.css"

const Invoice =()=>{
    const {order_id} = useParams();
    const [data] = useGetInvoiceData(order_id);
    const [totalAmount , setTotalAmount] = useState(0.0);
    const [print,setprint] = useState(true);

    useEffect(()=>{
        if (data && data?.order_product_detail.length > 0) {
            const sum = data?.order_product_detail.reduce((accumulator, item) => {
              const gst = ((item.gst_rate / 100) * item.price).toFixed(2) * item.qty;
              const total_price = (item.price * item.qty) + gst;
              return accumulator + total_price;
            }, 0);
          
            setTotalAmount(sum);
        }
    
    },[data])

    return (
        <>
<div className="invoice_body border border-gray-50 m-5 font-sans">
  <div className=" min-w-fit max-w-600 mx-auto border border-solid border-gray-300 p-10">
    <div className="invoice-heading">
      <h1 className="text-[#1A7499] my-2 text-4xl font-bold">Jay Plastic</h1>
      <p className="text-[#555] mb-2"><span className="font-bold">Date:</span> {data?.order_palace_date}</p>
      <p className="text-[#555] mb-2"><span className="font-bold">GST No:&nbsp;</span>24AAGFF5319K1ZE</p>
    </div>
    <div className="customer-details mt-4">
      <h2 className="text-[#333] text-3xl font-bold mt-7 mb-2">Invoice To:</h2>
      <p className="text-[#555]"><span className="font-bold">Name:</span> {data?.user_name}</p>
      <p className="text-[#555]"><span className="font-bold">Email:</span> {data?.user_email}</p>
      <p className="text-[#555]"><span className="font-bold">Mobile No:</span> {data?.user_mobile}</p>
      <p className="text-[#555]"><span className="font-bold">Company Name:</span> {data?.user_company}</p>
    </div>
    <div className="invoice-body mt-4">
      <table className="w-full border-collapse mt-[20px]">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-[10px] text-left">Item</th>
            <th className="border border-gray-300 p-[10px] text-left">Product Name</th>
            <th className="border border-gray-300 p-[10px] text-left">Quantity</th>
            <th className="border border-gray-300 p-[10px] text-left">Unit Price</th>
            <th className="border border-gray-300 p-[10px] text-left">GST</th>
            <th className="border border-gray-300 p-[10px] text-left">Total</th>
          </tr>
        </thead>
        <tbody>
          {data?.order_product_detail?.map((item, index) => (
            <tr key={index}>
              <td className="border border-gray-300 p-[10px] text-left">{index + 1}</td>
              <td className="border border-gray-300 p-[10px] text-left">{item.p_title}</td>
              <td className="border border-gray-300 p-[10px] text-left">{item.qty}</td>
              <td className="border border-gray-300 p-[10px] text-left">{item.price}</td>
              <td className="border border-gray-300 p-[10px] text-left">{item.gst_rate}%</td>
              <td className="border border-gray-300 p-[10px] text-left">{((item.price * item.qty) * (item.gst_rate / 100) + (item.qty * item.price)).toFixed(2)}</td>
            </tr>
          ))}
          <tr className="bg-gray-200">
            <td className="border border-gray-300 p-[10px] text-right font-bold" colSpan="5">Total:</td>
            <td className="border border-gray-300 p-[10px] text-left font-bold">₹{totalAmount.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="company-details mt-4">
      <p className="text-[#555]"><span className="font-bold">Jay plastic Mobile:</span> +9876543210</p>
      <p className="text-[#555]"><span className="font-bold">Jay plastic Email:</span> info@company.com</p>
    </div>
  </div>

</div>
<div className="flex justify-center">
    { print === true ?   <button className="bg-blue-300 p-2 text-md rounded" onClick={async () => {
            await setprint(false);

            await window.print()
             setprint(true);
        }}>Print</button>:null
    }

    </div>
  </>


    )
}
export default Invoice;