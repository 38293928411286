import React, { useState, useEffect } from 'react';

const Try = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/route_customer.php`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoidG9wZ3Jvd3RoIiwibW9iaWxlTnVtYmVyIjoiOTMyODk0MjU1NiIsInJvbGUiOiIwIiwicHJpY2VSb2xlIjoiQyIsImV4cCI6MTcwNDIxMzg5MX0.olV89YDbLaYRvh5w4pU_A8sCincmdSYEvGE5lptn1qI'
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error:', error.message);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {data ? (
                <div>
                    <p>{data.message}</p>
                    {data.data && (
                        <pre>{JSON.stringify(data.data, null, 2)}</pre>
                    )}
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </>
    );
};

export default Try;
