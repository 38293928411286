import { useState, useEffect } from "react";
import Cookies from 'js-cookie';
function useGetAllGstRate() {

    const [gstRates, setGstRetes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchGstRates = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/route_gst.php`,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('AdminToken')})`
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }

            const data = await response.json();
            setGstRetes(data.gst_rates);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(()=>{fetchGstRates()},[]);
    return {
        gstRates,
        fetchGstRates,
        loading,
        error
    }

}
export default useGetAllGstRate;