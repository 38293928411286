import { Link, useParams } from "react-router-dom";
import { MdOutlineArrowBack } from "react-icons/md";
import useGetCustomerDetails from "../hook/useGetCustomerDetails";

const CustomerDetails = () => {

    const {id}=useParams();
    const [data]= useGetCustomerDetails(id);
    console.log(data)
  return (
    <>
      <div class="w-5/6 p-5">
        <Link
          to="/admin"
          class="text-2xl flex w-fit justify-start items-center bg-gray-700 text-white pl-2 py-2 pr-3 rounded-lg"
        >
          <MdOutlineArrowBack className="text-white" /> <span>Back</span>
        </Link>
        <div class="bg-gray-200 rounded-md p-4 mt-5">
          <div class="px-4 pb-4">
            <div class="mb-4 flex border-b border-gray-600 p-2 w-full">
              <div class="mb-2 font-semibold  w-1/2">Customer Name:</div>
              <div class="text-lg pl-2 text-gray-500 text-left">
                {data.userName}
              </div>
            </div>
            <div class="mb-4 flex border-b border-gray-600 p-2 w-full">
              <div class="mb-2 font-semibold  w-1/2">
                E-mail Address:
              </div>
              <div class="text-lg pl-2 text-gray-500 text-left">
                {data.email ? data.email : " - "}
              </div>
            </div>
            <div class="mb-4 flex border-b border-gray-600 p-2 w-full">
              <div class="mb-2 font-semibold  w-1/2">
                Mobile Number:
              </div>
              <div class="text-lg pl-2 text-gray-500 text-left">
                {data.mobileNumber}
              </div>
            </div>
            <div class="mb-4 flex border-b border-gray-600 p-2 w-full">
              <div class="mb-2 font-semibold  w-1/2">
                Company Name:
              </div>
              <div class="text-lg pl-2 text-gray-500 text-left w-1/2 h-auto">
                {data.companyName}
              </div>
            </div>
            
            <div class="mb-4 flex border-b border-gray-600 p-2 w-full">
              <div class="mb-2 font-semibold  w-1/2">
                Customer Type:
              </div>
              <div class="text-lg pl-2 text-gray-500 text-left">
                {data.priceRole}
              </div>
            </div>
            <div class="mb-4 flex border-b border-gray-600 p-2 w-full">
              <div class="mb-2 font-semibold w-1/2">
                password :
              </div>
              <div class="text-lg pl-2 text-gray-500 text-left">
                {data.password}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDetails;