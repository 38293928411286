import { useEffect, useState,useContext } from "react";
import useGetAllCategory from "../hook/useGetAllCategory";
import { Link } from "react-router-dom";
import { useData } from "../services/context/DataContext";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import addToCartTodatabase from "../apiCalls/addToCart";
import { CartContext } from "../services/context/cartContext";
const Products = () => {
  const{updateCartFromAPI} = useContext(CartContext);
    const { categories } = useGetAllCategory();
    // const [data] = useGetAllUserProduct();
    const [category, setCategory] = useState("ALL");
    const [searchTerm, setSearchTerm] = useState("");
    const [filterData,setFilterData] = useState([]);
     const { data } = useData();
    const [isLogin, setIsLogin] = useState(false);
  


         const addtoCart = async (event, p_id, qty, price) => {
           event.preventDefault();
           event.stopPropagation();
           if (!isLogin) {
             Swal.fire("Please Login First!");
           } else {
             const temp = await addToCartTodatabase(p_id, qty, price);
             if (temp) {
               Swal.fire({
                 icon: "success",
                 title: "successfully added to cart",
                 showConfirmButton: false,
                 timer: 1500,
               });
                updateCartFromAPI();
             } else {
               Swal.fire("something went wrong");
             }
           }
         };


  
  const handleSearchData = (event) => {
    event.preventDefault();
    filterDataBasedOnCategoryAndSearchTerm();
  };
useEffect(() => {
            if (
              Cookies.get("AdminToken") !== undefined ||
              Cookies.get("UserToken") !== undefined
            ) {
              setIsLogin(true);
             } else {
              setIsLogin(false);
             }
  filterDataBasedOnCategoryAndSearchTerm();
}, [category, searchTerm, data]);

const filterDataBasedOnCategoryAndSearchTerm = () => {

  
  if(category === "ALL" && searchTerm === ""){
    setFilterData(data);
    return;
  }
  else{
    const temp = data.filter((item)=>{
      if(category === "ALL"){
        return item.p_title.toLowerCase().includes(searchTerm.toLowerCase());
      }
      else if(searchTerm === ""){
        return item.p_category === category;
      }
      else{
        return item.p_category === category && item.p_title.toLowerCase().includes(searchTerm.toLowerCase());
      }
    })
    setFilterData(temp);
  }

};

    

    return (
      <>
        <div className=" mt-24">
          <div className="w-full px-4">
            <div className="mx-auto mb-[20px] max-w-[510px] text-center lg:mb-10">
              <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px] dark:text-white">
                Our Products
              </h2>
              <span className="mx-auto mb-4 block h-1 w-24 bg-blue-600"></span>
            </div>
          </div>
          <form onSubmit={handleSearchData}>
            <div className=" block md:flex  md:justify-center md:items-center m-5">
              <select
                name="Category"
                id=""
                value={category}
                className="mb-2 md:mb-0 border border-gray-400 p-2 rounded-lg focus:outline-none"
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <option value="ALL">ALL</option>
                {categories?.map((item, index) => {
                  return (
                    <option key={index} value={item.category_name}>
                      {item.category_name}
                    </option>
                  );
                })}
              </select>

              <div className="flex w-full max-w-md border  border-gray-400 rounded-[7px] md:ml-2">
                <input
                  type="text"
                  name="serchValue"
                  className="w-full rounded-l-md focus:outline-none p-1.5"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                />

                <button
                  className="text-white px-6 text-base font-semibold py-2 focus:outline-none focus:ring-0 rounded-r-md"
                  type="submit"
                  style={{ backgroundColor: "#50b8e7" }}
                >
                  <svg
                    className="feather feather-search"
                    fill="none"
                    height="24"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="11" cy="11" r="8" />
                    <line x1="21" x2="16.65" y1="21" y2="16.65" />
                  </svg>
                </button>
              </div>
            </div>
          </form>
          <div className="product_card mx-5 md:mx-10 px-2 ">
            <div
              id="product_content"
              className="grid gap-4 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6 mb-6"
            >
              {filterData?.map((item, index) => (
                <Link
                  to={`/productDetails/${item.p_id}`}
                  className="hover:shadow-2xl shadow-md rounded-xl min-w-[9rem] max-w-sm text-center"
                  key={index}
                >
                  <img
                    loding="lazy"
                    className="rounded-t-lg min-h-32 max-h-80 w-full"
                    src={`${process.env.REACT_APP_PHP_APP_SERVER_URL}/${
                      item.img || "images/notfound.png"
                    }`}
                    alt="product"
                  />

                  <div className="px-5 pb-5">
                    <h3 className="text-gray-900 font-bold text-sm md:text-2xl tracking-tight mt-5">
                      {item.p_title}
                    </h3>

                    <p className=" text-sm md:text-xl font-semibold text-gray-500 my-2">
                      ₹{item.price}
                    </p>
                    <button
                      id="add_to_cart_btn"
                      onClick={(event) => {
                        addtoCart(event, `${item.p_id}`, 1, item.price);
                      }}
                      className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg px-3 md:px-8 py-2.5 text-center text-[0.7rem] md:text-lg"
                    >
                      Add to cart
                    </button>
                  </div>
                </Link>
              ))}
            </div>

            <p
              style={{
                textAlign: "center",
                marginBottom: "2rem",
                color: "green",
              }}
            >
              <b>Congratulation Yay! You have seen it all</b>
            </p>
          </div>
        </div>
      </>
    );
}
export default Products;