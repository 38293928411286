import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "js-cookie";

const AdminLayout = ()=>{

    const navigate = useNavigate();

    useEffect(()=>{
        if (Cookies.get("AdminToken") === undefined){
            navigate("/login")
        }
    },[navigate])

    return (
        <> 
            <Header />
            <div className="flex w-full mt-24">
            <Sidebar />
                <div className="flex-grow ">
                    <Outlet />
                </div>
            </div>
        </>
    )

}
export default AdminLayout;