import { createContext, useContext, useReducer, useEffect } from 'react';
import Cookies from 'js-cookie';

const DataContext = createContext();


const initialState = {
    data: [],
    loading: true,
    error: null,
};

const dataReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_SUCCESS':
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case 'FETCH_ERROR':
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

const DataProvider = ({ children }) => {
    const [state, dispatch] = useReducer(dataReducer, initialState);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/user/getProduct.php`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${Cookies.get('UserToken') || Cookies.get('AdminToken')})`
                    },
                })
                const data = await response.json();
                dispatch({ type: 'FETCH_SUCCESS', payload: data.data });
            } catch (error) {
                dispatch({ type: 'FETCH_ERROR', payload: error.message });
            }
        };

        fetchData();
    }, []);

    return <DataContext.Provider value={state}>{children}</DataContext.Provider>;
};

const useData = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};

export { DataProvider, useData };