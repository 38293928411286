import { useState } from "react";
import { FaUser } from "react-icons/fa";
import { MdOutlineAlternateEmail, MdWifiCalling3, MdDriveFileRenameOutline, MdOutlinePriceChange, MdPassword } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import useGetUserDetails from "../hook/useGetUserDetails";
import Cookies from 'js-cookie';

const EditUser = ()=>{
    const {id} = useParams();
    const [data,setData]=useGetUserDetails(id);
    const [error,setError] = useState("");
    const navigate = useNavigate();

    const handelData=(e)=>{
        const {name,value}=e.target;
        setData({...data,[name]:value})
    }

    const sendData = (e)=>{
        e.preventDefault();
        if(data.password !== data.c_password){
            setError("please check password and confirm password")
        }
        else{
            fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/route_customer.php`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('AdminToken')})`
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(res => {
                    if (res.success) {
                        navigate("/admin/user")
                        
                    }
                    else {
                        setError(data.message)
                    }
                })
                .catch(error => console.error('Error registering product:', error));
            setError("")

        }
     
    }
  
    return(
        <>
            <div className="min-h-screen flex flex-col items-center">
                <div className="flex flex-col  bg-gray-300 shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
                    <div className="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
                        Edit Customer</div>

                    <div className="mt-10">

                        <form onSubmit={sendData}>
                            <div className="flex flex-col mb-6">
                                <label htmlFor="name" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Customer Name:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <FaUser className=" text-xl mt-1" />
                                    </div>

                                    <input id="userName" type="text" name="userName" autoComplete="username" value={data.userName} className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Customer Name" onChange={(e)=>{handelData(e)}}/>
                                </div>
                            </div>

                            <div className="flex flex-col mb-6">
                                <label htmlFor="email" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">E-Mail Address:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <MdOutlineAlternateEmail className=" text-xl mt-1" />
                                    </div>

                                    <input id="userEmail" type="email" name="email" value={data.email} className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="E-Mail Address" onChange={(e) => { handelData(e) }} />
                                </div>
                            </div>

                            <div className="flex flex-col mb-6">
                                <label htmlFor="number" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Phone Number:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <MdWifiCalling3 className=" text-xl mt-1" />
                                    </div>

                                    <input id="userNumber" type="tel" name="mobileNumber" value={data.mobileNumber} pattern="\d{10}" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Mobile Number" onChange={(e) => { handelData(e) }} />
                                </div>
                            </div>

                            <div className="flex flex-col mb-6">
                                <label htmlFor="cname" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Company Name:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <MdDriveFileRenameOutline className=" text-xl mt-1" />
                                    </div>

                                    <input id="userCompanyName" type="text" name="companyName" value={data.companyName} autoComplete="Company Name" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Company Name" onChange={(e) => { handelData(e) }} />
                                </div>
                            </div>

                            <div className="flex flex-col mb-6">
                                <label htmlFor="type" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Customer type:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <MdOutlinePriceChange className=" text-xl mt-1" />
                                    </div>

                                    <select name="priceRole" defaultValue={data.priceRole} id="userPriceRole" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" onChange={(e) => { handelData(e) }}>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                    </select>
                                </div>
                            </div>

                            <div className="flex flex-col mb-6">
                                <label htmlFor="password" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Password:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <MdPassword className=" text-xl mt-1" />
                                    </div>

                                    <input id="userPassword" type="password" name="password" value={data.password} autoComplete="new-password" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Password" onChange={(e) => { handelData(e) }} />
                                </div>
                            </div>

                            <div className="flex flex-col mb-5">
                                <label htmlFor="password" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Confirm Password:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <MdPassword className=" text-xl mt-1" />
                                    </div>

                                    <input id="userCpassword" type="password" name="c_password" value={data.c_password} autoComplete="new-password" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Confirm Password" onChange={(e) => { handelData(e) }} />
                                </div>
                            </div>
                            <p id="user_error" className="text-red-500 mb-1">{error}</p>
                            <div className="flex w-full">
                                <button  name="create_btn" className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-400 hover:bg-blue-500 rounded py-2 w-full transition duration-150 ease-in">
                                    <span className="mr-2 uppercase">Create</span>
                                    <span>
                                        <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditUser;