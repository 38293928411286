import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AdminLayout from './Layout/AdminLayout';
import AdminUser from './admin/AdminUser';
import AdminProduct from './admin/AdminProduct';
import AdminOrder from './admin/AdminOrder';
import CreateProduct from './components/CreateProduct';
import EditProduct from './components/EditProduct';
import Try from './Try';
import CreateUser from './components/CreateUser';
import EditUser from './components/EditUser';
import ProductDetails from './components/ProductDetails';
import CustomerDetails from './components/CustomerDetails';
import OrderDetails from './components/OrderDetails';
import Login from './components/Auth/Login';
import CustomerLayout from './Layout/CustomerLayout';
import Home from './pages/Home';
import Products from './pages/Products';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import Cart from './pages/Cart';
import Order from './pages/Order';
import { CartProvider } from './services/context/cartContext';
import Invoice from './components/Invoice';

const appRouter = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/invoice/:order_id",
    element: <Invoice />,
  },
  {
    path: "/",
    element: <CustomerLayout />,
    children:[
      {
        path: "",
        element: <Home/>,
      },{
        path: "products",
        element: <Products />,
      },
      {
        path:"userorder",
        element: <Order />
      },
      {
        path:"userOrderDetails/:order_id",
        element: <OrderDetails />
      },
      {
        path: "productDetails/:id",
        element: <ProductDetails />,
      },
      {
        path: "about",
        element: <About />,
      }, {
        path: "contanctUs",
        element: <ContactUs />,
      }, {
        path: "cart",
        element: <Cart />,
      }
    ]
  },
  {
    path:"/admin",
    element: <AdminLayout />,
    children: [
      { path: "", element: <AdminUser /> },
      { path: "product", element: <AdminProduct /> },
      { path: "order", element: <AdminOrder /> },
      { path: "createProduct", element: <CreateProduct /> },
      { path: "editProduct/:id", element: <EditProduct /> },
      { path: "productDetails/:id", element: <ProductDetails /> },
      { path: "customerDetails/:id", element: <CustomerDetails/> },
      { path: "orderDetails/:order_id", element: <OrderDetails /> },
      { path: "createUser", element: <CreateUser />},
      { path: "editUser/:id", element: <EditUser />},

      
    ] 
  },
  { path: "try", element: <Try /> },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<CartProvider>
  <RouterProvider router={appRouter} />
  </CartProvider>
);

