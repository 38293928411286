import "../App.css";
const Loding = ()=>{
    return(
        <>
            <div id="loading" class="spinner_postion">
                <div class="spinner"></div>
            </div>
        </>
    )
}
export default Loding;