import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

const apiCalls = async () => {
    try {
        fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/user/route_order.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('UserToken') || Cookies.get('AdminToken')}`,
            },
        });
        return true;

        // const result = await response.json();

        // if (result.success) {
        //     return true;
        // } else {
        //     console.error(result.message || 'Failed to add to cart');
        //     return false;
        // }
    } catch (error) {
        console.error('Error adding to cart:', error);
        return false;
    }
};

const placeOrder = async () => {
    try {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'bg-green-600 text-white rounded-md py-2 px-3 m-2',
                cancelButton: 'bg-red-600 text-white rounded-md py-2 px-3 m-2'
            },
            buttonsStyling: false
        });

        const result = await swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You want to order!",
            showCancelButton: true,
            confirmButtonText: 'Yes, order it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        });

        if (result.isConfirmed) {
            const apiResponse = await apiCalls();

            if (!apiResponse) {
                await Swal.fire({
                    title: 'Something went wrong',
                    icon: 'error'
                });
            }
            else {
                await swalWithBootstrapButtons.fire(
                    'Ordered!',
                    'Your order has been saved',
                    'success'
                );
            }
            return true;
        }
        else{
            return false;   
        }
    } catch (error) {
        console.error('Error in placeOrder function:', error);
    }
};

export default placeOrder;
