import { useState, useEffect } from "react";
import Cookies from 'js-cookie';
function useGetCustomerDetails(id) {
    const [arrayValues, setArrayValues] = useState({ img: [] });
    const [error, setError] = useState("");
    useEffect(() => {
        fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/getUserDetails.php?id=${id}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('AdminToken')})`
            },
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setArrayValues(res.data);
                }
                else {
                    setError("somethig went wrong")
                }


            })
            .catch(e => console.log("error : " + e));

    }, [id])
    return [arrayValues, setArrayValues, error];
}
export default useGetCustomerDetails;