import { useEffect, useState,useContext } from "react";
import deleteProductInCart from "../apiCalls/deleteProductInCart";
import useGetAllCartProduct from "../hook/useGetAllCartProduct";
import Cookies from "js-cookie";
import placeOrder from "../apiCalls/placeOrder";
import { CartContext } from "../services/context/cartContext";
import { Link } from "react-router-dom";

const Cart = ()=>{
  const{updateCartFromAPI} = useContext(CartContext);
    const [data,setData] = useGetAllCartProduct();
    const [subTotal,setSubTotal] = useState(0);
    const [gst,setGst] = useState(0);
    const [total,setTotal] =useState(0);
    const [isLoding,setIsLoading] = useState(false);

    const deleteProduct = async (event,p_id)=>{
        event.preventDefault();
        event.stopPropagation();
      const isDeleted = await deleteProductInCart(p_id);
      if(isDeleted){
        const temp = data.filter((item)=>{
            return item.p_id !== p_id;
        }
        )
        setData(temp);
      }
    }

    const updateCart = (qty,p_id)=>{
        fetch(
          `${process.env.REACT_APP_PHP_APP_SERVER_URL}/user/route_cart.php`,
          {
            method: "PUT",
            body: JSON.stringify({
              qty: qty,
              p_id:p_id
            }),
            headers: {
              "Content-Type": "application/json",
             "Authorization":
                Cookies.get("AdminToken") || Cookies.get("UserToken"),
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if(data.success){
            setData((prevData)=>{
                 return prevData.map((product) => {
                   if (product.p_id === p_id) {
                     // Update quantity if the product ID matches
                     return { ...product, qty: qty };
                   }
                   return product;
                 });
            })
            updateCartFromAPI();
          }
          });
    }

    useEffect(()=>{
        let subTotal = 0;
        let gst = 0;
        data?.forEach((item)=>{
            subTotal += item.price * item.qty;
            gst += (item.gst/100) * (item.price * item.qty);
        })
        setSubTotal(subTotal);
        setGst(gst);
        setTotal(subTotal+gst);

    },[data])

    const orderPlaced = async ()=>{
      setIsLoading(true);
      const temp = await placeOrder();
      if(temp){
        window.location.reload();
      }else{
        setIsLoading(false);
      }
     
    }    
    return (
      <>
        <div className="w-full pb-5  pt-10 mt-24">
          <div className="mx-auto mb-[20px] max-w-[510px] text-center lg:mb-10">
            <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px] dark:text-white">
              Cart Items
            </h2>
            <span className="mx-auto mb-4 block h-1 w-24 bg-blue-600"></span>
          </div>

          <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
            <div className="w-full md:w-2/3">
              {data?.map((item, index) => {
                return (
                  <Link to={`/productDetails/${item.p_id}`}
                    className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start"
                    key={index}
                  >
                    <div className=" h-47 w-full md:w-40 md:h-32 overflow-hidden">
                      <img
                        src={`${process.env.REACT_APP_PHP_APP_SERVER_URL}/${item.img}`}
                        alt="product"
                        className="rounded-lg h-full w-full bg-cover"
                      />
                    </div>
                    <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                      <div className="mt-5 sm:mt-0">
                        <h2 className="text-lg font-bold text-gray-900">
                          {item.name}
                        </h2>
                        <p className="mt-1 text-xs text-gray-700">
                          {item.category}
                        </p>
                        <p className="mt-1 text-xs text-gray-400">
                        GST&nbsp;:&nbsp;{item.gst}%
                        </p>
                      </div>
                      <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                        <div className="flex items-center border-gray-100">
                          <span onClick={(event)=>{event.preventDefault();
                          if(item.qty!==1){
                            updateCart(item.qty-1,item.p_id)}}} className="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50">
                            -
                          </span>
                          <input
                            className="h-8 w-8 border bg-white text-center text-xs outline-none"
                            type="number"
                            min="1"
                            value={item.qty}
                            onChange={(event)=>{event.preventDefault();updateCart(event.target.value,item.p_id)}}
                          />
                          <span onClick={(event)=>{event.preventDefault();updateCart(item.qty+1,item.p_id)}} className="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50">
                            +
                          </span>
                        </div>
                        <div className="flex items-center space-x-4">
                          <p className="text-sm">{item.price}</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-5 w-5 cursor-pointer duration-150 hover:text-red-500"
                            onClick={(event) => {
                              
                              deleteProduct(event,item.p_id);
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>

            <div className="mt-6 h-full relative rounded-lg border bg-white p-6 shadow-md  md:mt-0 md:w-1/3">
              <div
                id="loading"
                className={`absolute inset-0 ${!isLoding ? "hidden":""} `}
                style={{ backdropFilter: "blur(10px)" }}
              >
                <div className="spinner_postion">
                  <div className="spinner"></div>
                </div>
              </div>
              <div className="mb-2 flex justify-between">
                <p className="text-gray-700">Subtotal</p>

                <p className="text-gray-700" id="subTotal">
                  <span>₹</span>
                  {subTotal}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-gray-700">GST</p>

                <p className="text-gray-700" id="GST">
                  <span>₹</span>
                  {gst?.toFixed(2)}
                </p>
              </div>
              <hr className="my-4" />
              <div className="flex justify-between">
                <p className="text-lg font-bold pr-2">Total</p>
                <div className="">
                  <p className="mb-1 text-lg break-all font-bold text-right" id="writeTotalPrice">
                    ₹{total}
                  </p>
                  <p className="text-sm text-gray-700">including GST</p>
                </div>
              </div>
              <button onClick={orderPlaced} className="mt-6 w-full rounded-md py-1.5 font-medium text-blue-50 bg-blue-500 hover:bg-blue-600">
                Check out
              </button>
            </div>
          </div>
        </div>
      </>
    );
}
export default Cart;    