import { FaUser, FaShoppingCart, FaMoneyBill, FaEye, FaRegEdit } from "react-icons/fa";
import { MdSearch, MdAdd, MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import useGetAllUser from "../hook/useGetAllUser";
import { useState,useEffect } from "react";
import Swal from 'sweetalert2'
import deleteUser from "../apiCalls/deleteUser";

const AdminUser = ()=>{

    const [data, setData,error]=useGetAllUser();
    const [searchTerm, setSearchTerm] = useState('');
    const [filterData, setFilterData] = useState([]);
    const [searchPriceRole,setSearchPriceRole] = useState("ALL");
    const [count,setCount]=useState();
    const navigate = useNavigate();
    

    useEffect(() => {
        setFilterData(data);
        setCount(data.length);
    }, [data]);

    const handleSearch = () => {

        let searchData = data.filter(item =>
            (searchPriceRole === 'ALL' || item.priceRole.includes(searchPriceRole)) &&
            (!searchTerm || item.userName.toLowerCase().includes(searchTerm))
        );
        setFilterData(searchData)
    }


    const handleDeleteUser=(id,event)=>{
        event.stopPropagation();
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {

            if (result.isConfirmed) {
                const isdeleted = deleteUser(id);
                if (isdeleted) {
                    const filterItem = data.filter(item => item.id !== id)
                    setData(filterItem);
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });
                }
            }
        });
    }
    // console.log(data);
    return (
        <>
            <div className="w-full">
                <h1 className="font-bold p-5 text-2xl">Customer</h1>


                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-6">

                    <div className="bg-white shadow-md rounded-lg p-4">
                        <div className="flex items-center">
                            <div className="bg-blue-700 text-white text-4xl h-14 w-14 rounded-lg flex justify-center items-center">
                                <FaUser style={{ fontSize: "40px" }} />
                            </div>

                            <div className="ml-4">
                                <div className="text-blue-700 text-2xl font-bold">

                                {count}

                                </div>
                                <div className="text-xs font-bold text-gray-500 uppercase">Customers</div>
                            </div>
                        </div>
                    </div>


                    <div className="bg-white shadow-md rounded-lg p-4">
                        <div className="flex items-center">
                            <div className="bg-red-500 text-white text-4xl h-14 w-14 rounded-lg flex justify-center items-center">
                                <FaShoppingCart style={{ fontSize: "40px" }} />
                            </div>
                            <div className="ml-4">
                                <div className="text-red-500 text-2xl font-bold">295</div>
                                <div className="text-xs font-bold text-gray-500 uppercase">Shipped Orders</div>
                            </div>
                        </div>
                    </div>


                    <div className="bg-white shadow-md rounded-lg p-4">
                        <div className="flex items-center">
                            <div className="bg-yellow-300 text-white text-4xl h-14 w-14 rounded-lg flex justify-center items-center">
                                <FaMoneyBill style={{ fontSize: "40px" }} />
                            </div>
                            <div className="ml-4">
                                <div className="text-yellow-300 text-2xl font-bold">$8,853,839</div>
                                <div className="text-xs font-bold text-gray-500 uppercase">Received Payments</div>
                            </div>
                        </div>
                    </div>
                </div>


                    <div className="my-5 px-6">
                        <div className="flex items-center justify-between gap-4">

                            <div className="flex space-x-4">

                                <select name="search_Role" defaultValue={searchPriceRole} id=""
                                    className="bg-gray-300 hover:bg-gray-400 border border-gray-400 text-center rounded-[7px] focus:outline-none w-20" onChange={(e)=>{setSearchPriceRole(e.target.value)}}>
                                    <option value="ALL">All</option>
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="C">C</option>
                                    <option value="D">D</option>
                                </select>


                                <div className="flex w-full border border-gray-400 rounded-[7px] ml-2">

                                    <input type="text" name="serchValue" className="w-full rounded-l-md focus:outline-none pl-2"
                                        placeholder="Search" value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />


                                    <button
                                        className="bg-blue-600 text-white px-6 text-base font-semibold py-2 focus:outline-none focus:ring focus:border border-blue-600 rounded-r-md"
                                        onClick={handleSearch}>
                                    <MdSearch className="text-2xl" />
                                    </button>
                                </div>
                            </div>

                            <div className="flex items-end justify-center gap-3">
                                <div className="md:col h-full">
                                    <div className="text-right h-full pt-3">
                                    <Link to="/admin/createUser">
                                        <button id="" type="button" className="bg-green-300 inline-flex items-center text-base rounded-md h-10 p-2" >
                                            <MdAdd className="text-2xl" /> <span>Create User</span>
                                        </button>
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <p className="text-red-500 ml-4 text-md">{error}</p>
                    <div className="mt-4 mx-4 overflow-y-auto" style={{height: "600px"}}>
                        <div className="w-full overflow-hidden rounded-lg border border-gray-400">
                            <div className="w-full overflow-x-auto">

                                <table className="w-full ">
                                    <thead>
                                        <tr className="text-xs font-semibold tracking-wide text-left text-gray-700 uppercase border-b bg-gray-400">
                                            
                                            <th className="px-4 py-3">No.</th>
                                            <th className="px-4 py-3">Client</th>
                                            <th className="px-4 py-3">Role</th>
                                            <th className="px-4 py-3">Company Name</th>
                                            <th className="px-4 py-3 w-48"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                   
                                    {
                                        filterData.map((user,index)=>{
                                            return(
                                                
                                                <tr key={index} className='bg-gray-50 hover:bg-gray-100 text-gray-700 ' onClick={() => { navigate(`/admin/customerDetails/${user.id}`)}}>
                                                        <td className='px-4 py-3'>{index+1}</td>
                                                        <td className='px-4 py-3 w-2/12'>
                                                            <div className='flex items-center text-sm'>
                                                                <p className='font-semibold'>{user.userName}</p>
                                                            </div>
                                                        </td>
                                                        <td className='px-4 py-3 w-1/12'>
                                                            {user.priceRole}
                                                        </td>
                                                        <td className='px-4 py-3 w-2/5'>
                                                            <div className='w-full h-6 overflow-hidden'>
                                                                <div className='h-full'>
                                                                    {user.companyName}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='text-right px-4 py-3'>
                                                            <div className='flex items-center justify-between'>
                                                            <Link to={`/admin/customerDetails/${user.id}`} style={{ fontSize: '18px' }} onClick={(event)=>{event.stopPropagation();}}><FaEye className="text-xl text-gray-500" /></Link>
                                                            <Link to={`/admin/editUser/${user.id}`} onClick={(event) => { event.stopPropagation(); }} style={{ fontSize: '18px' }}><FaRegEdit className="text-xl text-gray-500" /></Link>

                                                            <button to="/admin/editUser/12" className='delete_btn_ajax' style={{ fontSize: '18px' }} onClick={(event) => { handleDeleteUser(user.id,event) }}><MdDelete className="text-xl text-gray-500" /></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                
                                            )
                                        })
                                    }
                                    

                        </tbody>
         


                    </table>


            </div>
        </div >
                </div >
      
        </div >
        </>
    )
}
export default AdminUser;