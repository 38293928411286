import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { DataProvider } from "../services/context/DataContext";
// import { CartProvider } from "../services/context/cartContext";

const CustomerLayout = ()=>{
    return (
      <>
      {/* <CartProvider>   */}
      <Header />
        <DataProvider>
          <Outlet />
        </DataProvider>
        <Footer />
        {/* </CartProvider > */}
     
      </>
    );
}
export default CustomerLayout;
