import Cookies from "js-cookie";

async function addToCartTodatabase(p_id,qty,price) {
    const data = {
        p_id: p_id,
        price: price,
        qty: qty,
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/user/route_cart.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('UserToken') || Cookies.get('AdminToken')}`,
            },
            body: JSON.stringify(data),
        });

        const result = await response.json();

        if (result.success) {
            return true;
        } else {
            console.error(result.message || 'Failed to add to cart');
            return false;
        }
    } catch (error) {
        console.error('Error adding to cart:', error);
        return false;
    }
}


export default addToCartTodatabase;