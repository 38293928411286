import Cookies from 'js-cookie';
import { createContext, useEffect } from 'react';
import { useState } from 'react';

export const CartContext = createContext();


export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(0);
  
    const updateCartFromAPI = async () => {
      try {
       
        const response = await fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/user/cartCount.php`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Cookies.get('UserToken') || Cookies.get('AdminToken')}`
            },
        });
        const data = await response.json();
        setCart(data.data);
      } catch (error) {
        console.error('Error updating cart from API:', error);
      }
    };

    useEffect(() => {
        updateCartFromAPI();
      }, []);
    
    const value = {
        cart,
        updateCartFromAPI,
      };
    
      return (
        <CartContext.Provider value={value}>{children}</CartContext.Provider>
      );
    
};