import Cookies from 'js-cookie';

const login = async (phoneNumber, password) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/route_login.php`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mobileNumber: phoneNumber,
                password: password,
            }),
        });

        const data = await response.json();
        

        if (data.success) {
            
            if(data.role === "1"){
                const expirationTime = new Date().getTime() + 60 * 1000 * 60; // 1000 milliseconds * 60 seconds = 1 minute
                Cookies.set('AdminToken', data.token, { expires: new Date(expirationTime) });

            }
            else{
                const expirationTime = new Date().getTime() + 60 * 1000 * 60;
                Cookies.set('UserToken', data.token, { expires: new Date(expirationTime) });
            }
            return { success: true,role:data.role };
        } else {
            return { success: false, error: data.error || 'Login failed' };
        }
    } catch (error) {
        return { success: false, error: "An error occurred during login." };
    }
};

export default login;
