import { useEffect, useState,useContext } from "react";
import { useParams } from "react-router-dom";
import useGetProductDetails from "../hook/useGetProductDetails";
import { FiPackage } from "react-icons/fi";
import { FaMoneyBill, FaPhotoVideo } from "react-icons/fa";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import addToCartTodatabase from "../apiCalls/addToCart";
import { CartContext } from "../services/context/cartContext";

const ProductDetails = () => {
  const{updateCartFromAPI} = useContext(CartContext);
  const [qty,setqty] = useState(1);
  const {id} = useParams();
  const [data] = useGetProductDetails(id);
  const [mainUrl,setMainUrl] = useState();
  const [img,setImg]=useState();
  const [isAdmin,setIsAdmin] = useState(false);
  const [isLogin,setIsLogin] = useState(false);
  const [error,setError] = useState(false);

  useEffect(()=>{
    if(Cookies.get("AdminToken")!==undefined){
      setIsAdmin(true);
      setIsLogin(true);
    }
     if (Cookies.get("UserToken") !== undefined) {
       setIsLogin(true);
     }
    const { img } = data || {};
    const imageName = img?.[0]?.name;
    
    setMainUrl(imageName);

    const newImg = img.slice(1);
    setImg(newImg);

  },[data])

  const changeMainUrl = (url,index)=>{

    const temp = [...img]
    temp[index].name = mainUrl;
    setImg(temp);
    setMainUrl(url)
  }

  const addtoCart = async ( p_id, price) => {
    if (!isLogin) {
      Swal.fire("Please Login First!");
    } else {
      if(qty<1){
        setError(true);
      }else{
        setError(false);
      const temp = await addToCartTodatabase(p_id, qty, price);
      if (temp) {
        Swal.fire({
          icon: "success",
          title: "successfully added to cart",
          showConfirmButton: false,
          timer: 1500,
        });
        updateCartFromAPI();
      } else {
        Swal.fire("something went wrong");
      }
    }
    }
  };

  const handleQuantityChange = (e) => {
    const inputValue = e.target.value;
    setqty(inputValue);

    if(inputValue !== ""){
      const parsedValue = parseInt(inputValue);
  
      
      if (parsedValue >= 0) {
  
        setqty(parsedValue);
      } else {

        setqty(1);
      }
    }
  };
  return (
    <>
      <section className="py-6 mt-24">
        <div className="container px-4 mx-auto" data-path="0.0">
          <div className="max-w-lg mx-auto lg:max-w-6xl">
            <div className="flex flex-wrap mb-8">
              <div className="w-full lg:w-1/2 p-4">
                <div className="lg:max-w-md">
                  {data.p_id && (
                    <img
                      className="block w-full rounded-xl mb-4"
                      src={`${process.env.REACT_APP_PHP_APP_SERVER_URL}/images/product_${data?.p_id}/${mainUrl}`}
                      alt="Product"
                    />
                  )}

                  <div className="flex -mx-2">
                    {img?.map((item, index) => {
                      return (
                        <div
                          className="w-1/4 px-2"
                          key={index}
                          onClick={() => {
                            changeMainUrl(`${item.name}`, index);
                          }}
                        >
                          <button className="block w-full">
                            <img
                              className="block w-full rounded-xl"
                              src={`${process.env.REACT_APP_PHP_APP_SERVER_URL}/images/product_${data.p_id}/${item.name}`}
                              alt=""
                            />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <div className="max-w-lg lg:ml-auto">
                  <h1 className="text-rhino-700 font-semibold text-4xl mb-2 font-heading">
                    {data?.p_title}
                  </h1>
                  <p className="text-sm text-rhino-400 font-medium mb-6">
                    {data?.p_category}
                  </p>
                  <p className="text-sm text-rhino-300 mb-6">{data?.p_desc}</p>
                  <div className="mb-2">
                    <h1 className=" text-lg text-rhino-500">
                      Customer Price :
                    </h1>
                  </div>

                  <div className="mb-6">
                    <h2 className="text-4xl font-semibold text-rhino-700">
                      ₹ {data?.customer_price}
                    </h2>
                  </div>
                  {isAdmin ? (
                    <>
                      <div className="mb-2">
                        <h1 className=" text-lg text-rhino-500">
                          Distributor Price :
                        </h1>
                      </div>

                      <div className="mb-6">
                        <h2 className="text-4xl font-semibold text-rhino-700">
                          ₹ {data?.dist_price}
                        </h2>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="mb-6">
                    <h2 className="mb-2 text-lg font-bold text-gray-700 dark:text-gray-400">
                      Product Specs :
                    </h2>
                    <div className="bg-gray-100 dark:bg-gray-700 rounded-xl">
                      <div className="p-3 lg:p-5 ">
                        <div className="p-2 rounded-xl lg:p-6 dark:bg-gray-800 bg-gray-50">
                          <div className="flex flex-wrap justify-center gap-x-10 gap-y-4">
                            <div className="w-full mb-4 md:w-2/5">
                              <div className="flex ">
                                <FiPackage className="mr-3 text-gray-500 dark:text-gray-400 text-3xl" />
                                <div>
                                  <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                    innner package
                                  </p>
                                  <h2 className="text-base font-semibold text-gray-700 dark:text-gray-400">
                                    {data.p_inner}
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div className="w-full mb-4 md:w-2/5">
                              <div className="flex ">
                                <FiPackage className="mr-3 text-gray-500 dark:text-gray-400 text-3xl" />
                                <div>
                                  <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                    master package
                                  </p>
                                  <h2 className="text-base font-semibold text-gray-700 dark:text-gray-400">
                                    {data.p_master}
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div className="w-full mb-4 lg:mb-0 md:w-2/5">
                              <div className="flex ">
                                <FaMoneyBill className="mr-3 text-gray-500 dark:text-gray-400 text-3xl" />
                                <div>
                                  <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                    GST Rate:
                                  </p>
                                  <h2 className="text-base font-semibold text-gray-700 dark:text-gray-400">
                                    {data.p_gst}
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div className="w-full mb-4 lg:mb-0 md:w-2/5">
                              <div className="flex ">
                                <FaPhotoVideo className="mr-3 text-gray-500 dark:text-gray-400 text-3xl" />
                                <div>
                                  <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                    Product Img :
                                  </p>
                                  <h2 className="text-base font-semibold text-gray-700 dark:text-gray-400">
                                    {data.img?.length}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full xs:w-4/12 md:w-3/12  mb-4 xs:mb-0">
                    <div className="h-full xs:w-full  px-4 rounded-sm border border-coolGray-200 gap-4 inline-flex items-center justify-between">
                      <div className="cursor-pointer text-coolGray-300 hover:text-coolGray-400 transition duration-200" onClick={()=>{if(qty>1){
                        setqty((prev)=>prev-1)
                      }}}>
                        -
                      </div>
                      <input
                        type="number"
                        className="text-center w-20 text-coolGray-700 text-sm focus:outline-none p-2"
                        min={1}
                        value={qty}
                        onChange={(e) => {
                          handleQuantityChange(e)
                        }}
                      />
                      <div className="cursor-pointer text-coolGray-300 hover:text-coolGray-400 transition duration-200" onClick={()=>{
                        if(qty===""){
                          setqty(1);
                        }else{
                        setqty((prev)=>prev+1)}}}>
                        +
                      </div>
                    </div>
                  </div>
                  {
                    error ? (<p className="text-red-400 text-sm mb-2">please select 1 or more qty.</p>):null
                  }
                  <button className=" w-full px-3 py-4 rounded-sm text-center text-white text-xl font-medium bg-gray-500 hover:bg-gray-700 transition duration-200" onClick={()=>{addtoCart(`${id}`,data?.customer_price)}}>
                    Add to cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ProductDetails;
