import { Link, useParams } from "react-router-dom";
import useGetOrderDetails from "../hook/useGetOrderDetails";
import { useEffect, useState } from "react";
import updateOrderStatus from "../apiCalls/updateOrderStatus";
import Cookies from "js-cookie";

const OrderDetails = () =>{

    const {order_id} =useParams();
    const [data] = useGetOrderDetails(order_id);
    const [totalAmount , setTotalAmount] = useState();
    const [isHiddden , setIsHidden] = useState(true);
    const [orderStatus , setOrderStatus] = useState();
    const [error , setError] = useState();
    const [isAdmin,setIsAdmin] = useState(false);


    const updateHiddenState = () =>{
      setIsHidden(false)
    }
    

    const getOrderSatus = (e) =>{
       if (e.target.value === "2") {
         setError("Please select a status");
       } else {
         const newStatus = e.target.value === "0" ? 0 : 1;
         const temp = updateOrderStatus(order_id, newStatus);

         if (temp) {
           setOrderStatus(newStatus === 1);
           setIsHidden(true);
         }
       }
    }

    useEffect(()=>{
      if(Cookies.get("AdminToken")!==undefined){
        setIsAdmin(true);
      }
      if (data && data.length > 0) {
          const sum = data.reduce((accumulator, item) => {
            const gst = ((item.gst_rate / 100) * item.price).toFixed(2) * item.qty;
            const total_price = (item.price * item.qty) + gst;
            return accumulator + total_price;
          }, 0);
        
          setTotalAmount(sum);
          setOrderStatus(data[0]?.order_status === 1 ? true : false);
        }
        

    },[data])

    return (
      <>
        <div className="w-full pb-5 pt-10">
          <div className="mx-auto mb-[20px] max-w-[510px] text-center lg:mb-10">
            <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px] dark:text-white">
              Order Items
            </h2>
            <span className="mx-auto mb-4 block h-1 w-24 bg-blue-600"></span>
          </div>
          <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
            <div className="w-full md:w-2/3">
              {data?.map((item, index) => {
                return (
                  <div
                    className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start"
                    key={index}
                  >
                    <div className="h-47 w-full md:w-40 md:h-32 overflow-hidden">
                      <img
                        src={`${process.env.REACT_APP_PHP_APP_SERVER_URL}/${item.img}`}
                        alt="product-image"
                        className="w-full h-full bg-cover rounded-lg sm:w-40"
                      />
                    </div>
                    <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                      <div className="mt-5 sm:mt-0">
                        <h2 className="text-lg font-bold text-gray-900">
                          {item?.p_title}
                        </h2>
                        <p className="mt-1 text-xs text-gray-700">
                          {item?.p_category}
                        </p>
                        <p className="mt-1 text-xs text-gray-700">
                          GST Rate : {item?.gst_rate}%
                        </p>
                      </div>
                      <div className="mt-4 flex justify-between im sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                        <div className="flex items-center justify-end border-gray-100">
                          <span className="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50">
                            Qty
                          </span>
                          <input
                            className="h-8 w-8 max-w-fit border bg-white text-center text-xs outline-none"
                            type="number"
                            value={item?.qty}
                            disabled
                          />
                        </div>
                        <div className="space-x-4">
                          <p className="text-sm text-right">₹{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mt-6 h-full rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-1/3">
              <div className="mb-2 flex justify-between">
                <p className="mb-1 text-lg font-bold">Order Date:</p>
                {data ? (
                  <p className="text-sm text-gray-700">
                    {data[0]?.placed_date}{" "}
                  </p>
                ) : null}
              </div>
              <div className="flex justify-between">
                <p className="text-gray-700">Order Satus:</p>
                {isHiddden ? (
                  <p
                    className={`${
                      orderStatus ? "bg-green-400" : "bg-red-400"
                    } text-white rounded px-2 py-0.5`}
                    onClick={()=>{if(isAdmin){updateHiddenState()}}}
                  >
                    {orderStatus ? "compleate" : "pending"}
                  </p>
                ) : (
                  <select
                    className="rounded px-2 py-0.5 border"
                    onChange={(e) => {
                      getOrderSatus(e);
                    }}
                  >
                    <option value="2">select Status </option>
                    <option value="0">pending</option>
                    <option value="1">compleate</option>
                  </select>
                )}
              </div>
              <p className="text-red-400 break-all">{error}</p>
              <hr className="my-4" />

              <div className="flex justify-between flex-wrap">
                <p className="text-lg font-bold w-fit">Order Total:</p>
                <p className="mb-1 text-lg font-bold max-w-full break-all">
                  ₹{totalAmount?.toFixed(2)}
                </p>
              </div>
              <Link to={`/invoice/${order_id}`}>
                <button className="mt-6 w-full rounded-md py-1.5 font-medium text-blue-50 bg-blue-500 hover:bg-blue-600">
                  invoice dowload
                </button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );

}
export default OrderDetails;