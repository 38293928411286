import { useState, useEffect } from "react";
import Cookies from 'js-cookie';

const useGetInvoiceData = (id) => {
    const [invoiceData, setInvoiceData] = useState({"order_product_detail":[]});
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/user/getInvoiceData.php?order_id=${id}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('UserToken') || Cookies.get('AdminToken')}`
            },
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setInvoiceData(res.data);
                }
            })
            .catch(e => console.log("error : " + e));
    },[id])
    
    return [invoiceData];
    };
    export default useGetInvoiceData;