import { useState } from "react";
import { FaUser } from "react-icons/fa";
import { MdOutlineAlternateEmail, MdWifiCalling3, MdDriveFileRenameOutline, MdOutlinePriceChange, MdPassword } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
const CreateUser = () => {  
    const [data, setData] = useState({"priceRole":"A"});
    const [error,setError] = useState("");
    const navigate = useNavigate();

    const handleData = (e)=>{
       const { name ,value}= e.target;
        setData({ ...data,[name]:value })
    }
    const sendData=(e)=>{
        e.preventDefault();


        fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/route_customer.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('AdminToken')})`
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {

                // Handle success or failure
                if (data.success) {
                    setError("")
                    navigate("/admin")
                }
                else {
                    setError(data.message)
                }
            })
            .catch(error => console.error('Error registering product:', error));
    }
    return(
        <>
            <div className="min-h-screen flex flex-col items-center">
                <div className="flex flex-col  bg-gray-300 shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
                    <div className="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
                        Create Customer</div>

                    <div className="mt-10">

                        <form onSubmit={(e)=>sendData(e)}>
                            <div className="flex flex-col mb-6">
                                <label htmlFor="name" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Customer Name:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <FaUser className=" text-xl mt-1"/>
                                    </div>

                                    <input id="userName" type="text" name="name" autoComplete="username" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Customer Name" onChange={(e)=>{handleData(e)}} required/>
                                </div>
                            </div>

                            <div className="flex flex-col mb-6">
                                <label htmlFor="email" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">E-Mail Address:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <MdOutlineAlternateEmail className=" text-xl mt-1" />
                                    </div>

                                    <input id="userEmail" type="email" name="email" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="E-Mail Address" onChange={(e) => { handleData(e) }} />
                                </div>
                            </div>

                            <div className="flex flex-col mb-6">
                                <label htmlFor="number" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Phone Number:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <MdWifiCalling3 className=" text-xl mt-1" />
                                    </div>

                                    <input id="userNumber" type="tel" name="number" pattern="\d{10}" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Mobile Number" onChange={(e) => { handleData(e) }} required />
                                </div>
                            </div>

                            <div className="flex flex-col mb-6">
                                <label htmlFor="cname" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Company Name:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <MdDriveFileRenameOutline className=" text-xl mt-1" />
                                    </div>

                                    <input id="userCompanyName" type="text" name="companyName" autoComplete="Company Name" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Company Name" onChange={(e) => { handleData(e) }} required />
                                </div>
                            </div>

                            <div className="flex flex-col mb-6">
                                <label htmlFor="type" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Customer type:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <MdOutlinePriceChange className=" text-xl mt-1" />
                                    </div>

                                    <select name="priceRole" defaultValue="A" id="userPriceRole" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" onChange={(e) => { handleData(e) }} >
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                        <option value="D">E</option>
                                    </select>
                                </div>
                            </div>

                            <div className="flex flex-col mb-6">
                                <label htmlFor="password" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Password:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <MdPassword className=" text-xl mt-1" />
                                    </div>

                                    <input id="userPassword" type="password" autoComplete="new-password" name="password" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Password" onChange={(e) => { handleData(e) }} required />
                                </div>
                            </div>

                            <div className="flex flex-col mb-6">
                                <label htmlFor="password" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Confirm Password:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 -top-0.5 h-full w-10 text-gray-400">
                                        <MdPassword className=" text-xl mt-1" />
                                    </div>

                                    <input id="userCpassword" type="password" autoComplete="new-password" name="c_password" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Confirm Password" onChange={(e) => { handleData(e) }} required />
                                </div>
                            </div>
                            <p id="user_error" className="text-red-500">{error}</p>
                            <div className="flex w-full">
                                <button type="submit"name="create_btn" className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-400 hover:bg-blue-500 rounded py-2 w-full transition duration-150 ease-in">
                                    <span className="mr-2 uppercase">Create</span>
                                    <span>
                                        <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreateUser;