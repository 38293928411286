import Swal from "sweetalert2";

const apiCall = async (data)=>{
    try {
        const response = await fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/sendTextEmail.php`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        return result.success;
    } catch (error) {
        console.error('Error during API call:', error);
        return false;
    }
    
}

const sendContactDetail = async (data)=>{
    try {
    const temp = await apiCall(data);
    if(temp){
        Swal.fire({
            icon: 'success',
            title: 'Message sent successfully',
            showConfirmButton: false,
            timer: 1500
          })
        return true;
    }
    else{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        return false;
    }

} catch (error) {
    console.error('Error during sendContactDetail:', error);
    return false;
}
}

export default sendContactDetail;