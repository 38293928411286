import { Link, useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import useGetAllUserOrder from "../hook/useGetAllUserOrder";

const Order = ()=>{

    const [data] = useGetAllUserOrder();
    const navigate = useNavigate();

    return(
        <>

<div className="mt-24 mx-10">
    <div className="mx-auto mb-[20px] max-w-[510px] text-center lg:mb-10">
        <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px] dark:text-white">
            Order Items
        </h2>
        <span className="mx-auto mb-4 block h-1 w-24 bg-blue-600"></span>

    </div>

    <div className="w-full overflow-hidden rounded-lg border border-gray-400 my-5 overflow-x-auto">
        <div className="">
            <table className="w-full text-center">
                <thead>
                    <tr className="text-xs font-semibold tracking-wide text-gray-700 uppercase border-b bg-gray-400 text-center">
                        <th className="px-4 py-3">Order No</th>
                        <th className="px-4 py-3">Total Quantity</th>
                        <th className="px-4 py-3">Price</th>
                        <th className="px-4 py-3">Status</th>
                        <th className="px-4 py-3">Order Date</th>
                        <th className="px-4 py-3"></th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y">
                    
                        {
                            data?.map((item,index)=>{
                                return(
                                    <tr className="bg-gray-50 hover:bg-gray-100 text-gray-700 cursor-pointer" key={index} onClick={()=>{
                                        navigate(`/userOrderDetails/${item.order_id}`)
                                    }} >
                            <td className="px-4 py-3 w-fit">{index+1}</td>
                            <td className="px-4 py-3 w-fit">{item.total_qty}</td>
                            <td className="px-4 py-3 w-fit">₹{item?.total_price.toFixed(2)}</td>
                           {
                            item?.order_status === 0 ? (<td className="px-4 py-3 w-fit "><span className="bg-red-400 text-white rounded px-2 py-0.5">Panding</span></td>):(<td className="px-4 py-3 w-fit"><span className="bg-green-400 text-white rounded px-2 py-0.5">compleate</span></td>)
                           }
                             
                          
                              
                          
                            <td className="px-4 py-3 w-fit">{item.placed_date}</td>
                            <td className="px-4 py-3">
                            <Link to={`/userOrderDetails/${item.order_id}`} style={{ fontSize: '18px' }} onClick={(event) => { event.stopPropagation(); }}><FaEye className="text-xl text-gray-500" /></Link>
                            </td>
                        </tr>
                                )
                            })
                        }

                </tbody>
            </table>
        </div>
    </div>
</div>

        </>
    )
}
export default Order;