import { useState } from "react";
import login from "../../apiCalls/login";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
const Login = ()=>{

    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [error,setError] = useState("");
    const navigate = useNavigate();

    const sendData = async (event)=>{
        event.preventDefault();
        const temp =  await login(phoneNumber,password);
        if(temp.success){
            setError("");
            if(temp.role === "1"){
                navigate("/admin");
            }
            else{
                navigate("/");
            }
        }else{
            setError(temp.error);
        }
        // console.log(phoneNumber,password);
            
    }
    return (
      <div>
        <Header />
        <div className="min-h-screen flex flex-col items-center justify-center text-center dark:text-white px-5">
          <div className="login_form flex flex-col px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
            <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px] dark:text-white">
              Login
            </h2>
            <span className="mx-auto mb-4 block h-1 w-24 bg-blue-600"></span>

            <div className="mt-10 text-left">
              <form onSubmit={sendData}>
                <div className="flex flex-col mb-6">
                  <label
                    htmlFor="phoneNumber"
                    className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                  >
                    Phone Number :
                  </label>
                  <div className="relative">
                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                      </svg>
                    </div>

                    <input
                      id="phoneNumber"
                      type="number"
                      name="phoneNumber"
                      className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400 text-black"
                      placeholder="Enter phone number"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-col mb-6">
                  <label
                    htmlFor="password"
                    className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                  >
                    Password:
                  </label>
                  <div className="relative">
                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                      <span>
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                        </svg>
                      </span>
                    </div>

                    <input
                      id="password"
                      type="password"
                      name="password"
                      autoComplete="new-password"
                      className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400 text-black"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex items-center mb-6 -mt-4">
                  <div className="flex ml-auto">
                    <a
                      href="#"
                      className="inline-flex text-xs sm:text-sm text-gray-600 hover:text-gray-900"
                    >
                      Forgot Your Password?
                    </a>
                  </div>
                </div>
                <p id="login_error" className="text-red-500">
                  {error}
                </p>
                <div className="flex w-full">
                  <button
                    type="submit"
                    className="login_btn flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-500 rounded py-2 w-full transition duration-150 ease-in"
                  >
                    <span className="mr-2 uppercase">Login</span>
                    <span>
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
}
export default Login;