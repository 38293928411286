import { MdSearch, MdAdd, MdDelete } from "react-icons/md";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import useGetAllProduct from "../hook/useGetAllProduct";
import { useState,useEffect } from "react";
import Swal from "sweetalert2";
import createCategory from "../apiCalls/createCategory";
import createGst from "../apiCalls/createGst";
import deleteProduct from "../apiCalls/deleteProduct";

const AdminProduct = ()=>{
    const [data,setData,error] = useGetAllProduct();
    const [searchTerm, setSearchTerm] = useState('');
    const [filterData,setFilterData] =  useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setFilterData(data);
    }, [data]);
    // console.log(data);
    // console.log(filterData)

    const handleSearch = ()=>{
      

        const tempData = searchTerm
            ? data.filter(item => item.p_title.toLowerCase().includes(searchTerm))
            : data;
            console.log(tempData);
        setFilterData(tempData)
    }

    const handleDeleteProduct = (id,event) => {
        
        event.stopPropagation();
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
           
                if (result.isConfirmed) {
                    const isdeleted = deleteProduct(id);
                    if (isdeleted) {
                        const filterItem = data.filter(item => item.p_id !== id)
                        setData(filterItem);
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });
                }
            }
        });
    }


    return(
        <>
            <div className="w-full">
                <h1 className="font-bold p-5 text-2xl">Products</h1>
               
                    {/* <!-- search-bar,select all and add button --> */}
                    <div className="my-5 px-6">
                        <div className="flex items-center justify-between gap-4">
                            {/* <!-- Search Box --> */}
                            <div className="flex space-x-4">
                                <div className="flex w-full border border-gray-400 rounded-[7px]">
                                    {/* <!-- Search Input --> */}
                                    <input type="text" name="serchValue" onChange={(e)=>{setSearchTerm(e.target.value)}} className="w-full rounded-l-md focus:outline-none pl-2" placeholder="Search" />

                                    {/* <!-- Search Button --> */}
                                <button name="searchBtn" onClick={handleSearch} className="bg-blue-600 text-white px-6 text-base font-semibold py-2 focus:outline-none focus:ring focus:border border-blue-600 rounded-r-md" type="submit">
                                    <MdSearch className="text-2xl"/>
                                    </button>
                                </div>
                            </div>

                            <div className="flex items-end justify-center">
                                {/* <!-- create Category --> */}
                            <div className="md:col h-full" onClick={createCategory}>
                                    <div className="text-right h-full pr-3 pt-3">
                                        <button id="" type="button" className="bg-green-300 inline-flex items-center text-base rounded-md h-10 p-2" >
                                        <MdAdd className="text-2xl"/> <span>Category</span>
                                        </button>
                                    </div>
                                </div>

                                {/* <!-- Create New Button --> */}
                                <div className="md:col h-full">
                                    <div className="text-right h-full pr-3 pt-3">
                                    <Link to="/admin/createProduct">
                                        <button id="" type="button" className="bg-amber-200 inline-flex items-center text-base rounded-md h-10 p-2" >
                                            <MdAdd className="text-2xl" /> <span>Product</span> 
                                            </button>
                                        </Link>
                                    </div>
                                </div>

                            <div className="md:col h-full" onClick={createGst}>
                                <div className="text-right h-full pr-3 pt-3">
                                    <button id="" type="button" className="bg-green-300 inline-flex items-center text-base rounded-md h-10 p-2" >
                                        <MdAdd className="text-2xl" /> <span>Create GST</span>
                                    </button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- product data --> */}
                    <p className="text-red-500 ml-4 text-md">{error}</p>
                    <div className="mt-4 mx-6 overflow-y-auto" style={{height: "600px"}}>
                        <div className="w-full overflow-hidden rounded-lg border border-gray-400">
                            <div className="w-full overflow-x-auto">
                                <table className="w-full">
                                    <thead>
                                        <tr className="text-xs font-semibold tracking-wide text-left text-gray-700 uppercase border-b bg-gray-400">
                                            <th id="column1" className="px-4 hidden">
                                                {/* <!-- delete btn --> */}
                                                <button id="toggleButton2" name="allDeleteBtn" type="submit" className="text-gray-800 text-xl font-bolder">
                                                    <i className='fa fa-trash-o'></i>
                                                </button>
                                            </th>
                                            <th className="px-4 py-3">Prodct No</th>
                                            <th className="px-4 py-3">Name</th>
                                            <th className="px-4 py-3">Category</th>
                                            <th className="px-4 py-3 w-48"></th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody className="bg-white divide-y">
                                        
                                {
                                    filterData.map((item,index)=>{
                                        return (
                                            <tr key={index} className='bg-gray-50 hover:bg-gray-100 text-gray-700 ' onClick={() => { navigate(`/admin/ProductDetails/${item.p_id}`) }}>
                                                <td id='' className='px-4 py-3 w-2/12 hidden ' >
                                                    <input type='checkbox' id='' name='del_chk[]' value='' />
                                                </td>
                                                <td className='px-4 py-3'>{index +1}</td>
                                                <td className='px-4 py-3 w-1/4'>
                                                    <div className='flex items-center text-sm'>
                                                        <p className='font-semibold'>{item.p_title}</p>
                                                    </div>
                                                </td>
                                                <td className='px-4 py-3 w-1/4'>
                                                    <div className='flex items-center text-sm'>
                                                        <p className='font-semibold'>{item.p_category}</p>
                                                    </div>
                                                </td>
                                                <td className='text-right px-4 py-3'>
                                                    <div className='flex items-center justify-between'>
                                                        <Link to={`/admin/ProductDetails/${item.p_id}`} style={{ fontSize: '18px' }} onClick={(event) => { event.stopPropagation(); }}><FaEye className="text-xl text-gray-500" /></Link>
                                                        <Link to={`/admin/editProduct/${item.p_id}`} style={{ fontSize: '18px' }} onClick={(event) => { event.stopPropagation(); }}><FaRegEdit className="text-xl text-gray-500" /></Link>
                                                        <button className='delete_product_btn_ajax' onClick={(event)=>{handleDeleteProduct(item.p_id,event)}} style={{ fontSize: '18px' }}><MdDelete className="text-xl text-gray-500" /></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            
                        </tbody>
                    </table>
            </div>
        </div >
                </div >
          
        </div >
        </>
    )
}
export default AdminProduct;