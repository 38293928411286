import { useState, useEffect } from "react";
import Cookies from 'js-cookie';
function useGetAllCategory() {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PHP_APP_SERVER_URL}/route_category.php`,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('AdminToken')})`
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }

            const data = await response.json();
            setCategories(data.categories);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return {
        categories,
        fetchCategories,
        loading,
        error,
       
    };
   
}
export default useGetAllCategory;