import { Link } from "react-router-dom";
import useGetAllDisplayCategory from "../hook/useGetAllDisplayCategory";

const ShopByCategory = () => {

    const [data] = useGetAllDisplayCategory();


    return (
        <>
            <section className="w-[100vw] bg-gray-100 pt-10 pb-12 lg:pt-[60px] lg:pb-[90px] dark:bg-slate-900">
    <div className="w-[90%] px-4 mx-auto">
        <div className=" flex">
            <div className="w-full px-4">
                <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
                    <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px] dark:text-white">
                        Shop By Category
                    </h2>
                    <span className="mx-auto mb-4 block h-1 w-24 bg-blue-600"></span>
                    <p className="text-base text-gray-400">
                        There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form.
                    </p>
                </div>
            </div>
        </div>


        <div className="-mx-4 flex flex-wrap">

            {
                data?.map((item, index) => {
                    return (
                      <div className="px-4 w-1/2 lg:w-1/4" key={index}>
                        <Link
                          to={`/products`}
                          className="group mb-10 block text-center"
                        >
                          <div className="mb-5 overflow-hidden rounded-lg">
                            <img
                              loading="lazy"
                              src="/WATER FAUCET .png"
                              alt={item.name}
                              className="w-full"
                            />
                          </div>
                          <h3 className="mb-1 text-xl font-semibold text-black group-hover:text-primary md:text-2xl lg:text-xl xl:text-2xl dark:text-white">
                            {item.name}
                          </h3>
                          <p className="text-base font-medium text-gray-400 dark:text-gray-600">
                            {item.value} products
                          </p>
                        </Link>
                      </div>
                    );
                })
            }
        </div>
        <div className="mt-10 text-center">
            <Link to="/products" className="inline-flex items-center justify-center rounded-md bg-blue-600 py-3 px-8 text-center text-base font-semibold text-white hover:bg-opacity-90">
                Explore All Category
            </Link>
        </div>
    </div>
</section>

        </>
    )
}
export default ShopByCategory;